<template>
  <div class="order">
    <!-- 查詢 -->
    <el-row :gutter="10" style="margin-bottom: 20px;">
      <el-col :span="4">
        <el-input v-model="tableParams.kw" placeholder="输入昵称/手机号搜索" @input="kwChange" class="mr20" />
      </el-col>
      <el-col :span="4">
        <el-select v-model="tableParams.goodsId" placeholder="请选择充值活动" @change="reload" clearable>
          <el-option v-for="(item,index) in activityList" :key="index" :label="item.activityName" :value="item.activityId">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <SelecteMS @reload="update"></SelecteMS>
      </el-col>
      <el-col :span="4">
        <el-date-picker v-model="dateToDate" type="daterange" align="center" unlink-panels range-separator="~"
          start-placeholder="预约开始日期" end-placeholder="预约结束日期" :picker-options="pickerOptions" @change="reload" clearable
          value-format="yyyy-MM-dd">
        </el-date-picker>
      </el-col>
    </el-row>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">

      <el-table-column label="充值时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.paymentDate || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="充值用户" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.nickName || "-" }}</span>
          <span>丨</span>
          <span>{{ scope.row.mobile || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="充值活动" align="center" prop="activityName">
      </el-table-column>
      <el-table-column label="充值金额" align="center">
        <template slot-scope="scope">
          <span>￥{{ scope.row.rechargeAmount || "0" }}</span>

        </template>
      </el-table-column>

      <el-table-column label="充值门店" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.shopName || "-" }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>

  </div>
</template>


<script>
import { orderList,getActivityTableList } from "@/api/activity/activity";

import { ObjToOpt, formatterDate } from "@/utils/utils"
import { rules } from "@/db/rules";
import { pickerOptions, activityStatus, orderStatusColor } from "@/db/objs";
import SelecteMS from '@/views/vip/components/selecteMS'
export default {
  components: {
    SelecteMS
  },
  data() {
    return {
      ObjToOpt,
      pickerOptions,
      activityStatus,
      orderStatusColor,
      formatterDate,
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],//表格数据
      tableParams: {
        page: 1,
        size: 10,
        kw: "",//查询关键字
        merchantId: "",//商户ID
        shopId: "",//店铺ID
        goodsId: "",
        dateFrom: '',
        dateTo: ''
      },
      // 弹框
      editShow: false, //弹框开关
      rules,
      params: {
        remark: "",
        roomId: ""
      },
      activityParams: {
        kw: '',
        activityStatus: '',
        validityStatus: '',
        merchantId: '',
        shopId: '',
        page: 1,
        pageSize:0,
      },
      activityList:[],
      timeToTime: [],//起止日期
      dateToDate: [], //日期
    }
  },
  created() {

  },
  watch: {
    timeToTime(val) {
      if (val) {
        this.params.startTime = val[0];
        this.params.endTime = val[1];
      } else {
        this.params.startTime = "";
        this.params.endTime = "";
      }
    },
  },
  mounted() {
    this.getActivityList()
    this.getTableList();// 【请求】表格数据
  },
  methods: {
    // 获取活动列表
    getActivityList() {
      getActivityTableList(this.activityParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.activityList = res.data.list;
        }
      });
    },
    // 【请求】表格数据
    getTableList() {
      if (this.dateToDate) {
        this.tableParams.dateFrom = this.dateToDate[0];
        this.tableParams.dateTo = this.dateToDate[1];
      } else {
        this.tableParams.dateFrom = "";
        this.tableParams.dateTo = "";
      }
      let data = this.tableParams;
      orderList(data).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          } else {
            this.tableData = [];
            this.total = 0;
          }

        }
      });
    },
    update(e) {
      this.tableParams.merchantId = e.merchantId;
      this.tableParams.shopId = e.shopId;
      this.reload()
    },




    // 【请求】订单修改
    getTableEdit() {
      let data = this.params;
      getTableEdit(data).then(res => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getTableList();
        }
      })
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getTableList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getTableList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getTableEdit();
        } else {
          return false;
        }
      });
    },



  }
}
</script>


<style lang="scss" scoped>
.order {
  padding: .15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}
</style>